.pagination{
  list-style: none;
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .hidden{
    display: none;
  }

  li{
    font-size: 1.4rem;
    border: 1px solid #ccc;
    min-width: 3rem;
    height: 3rem;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  p{
    margin-left: 1rem;
    .page{
      color: red;
    }
  }
.active{
  background-color: red;
  color: white;
}
}