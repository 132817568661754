.product {
 
  .card {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }

  form {
    label {
      display: block;
      font-size: 2rem;
      font-weight: 500;
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    input[type="password"],
    select,
    textarea {
      display: block;
      font-size: 1.6rem;
      width: 100%;
      padding: 1rem;
      font-weight: 300;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
      margin: 1rem auto;
    }

    .progress{
      background-color: #aaa;
      border: 1px solid transparent;
      border-radius: 10px;
      .progress-bar{
        background-color: var(--color-red);
        border: 1px solid transparent;
        border-radius: 10px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0 1rem;
      }
    }
  }
}

.group{
  border: 1px solid var(--color-red);
  padding: 5px;
}
