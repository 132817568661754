.table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;

    thead {
      border-top: 2px solid red;
      border-bottom: 2px solid red;
    }

    th {
      border: 1px solid #eee;
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
      &.icon {
        > * {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    tr {
      border-bottom: 1px solid #eee;
    }
  }

  .summary {
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .checkout{
      .btn{
        background-color: black !important;
        color: white;
      }
    }

    .card {
      padding: 1rem;
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          color: red;
        }
      }

      button {
        margin-top: 5px;
      }
    }

    button{
      margin-bottom: 1.5rem;
    }

  }
}

.count{
  display: flex;
  align-items: center;
  button{
    border: 1px solid black;
  }
  & > *{
    margin-right: 1rem;
  }
}
