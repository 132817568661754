.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  .loader_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    // .loader {
    //   width: 175px;
    //   height: 80px;
    //   display: block;
    //   margin:auto;
    //   background-image: radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
    //   background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
    //   background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
    //   background-repeat: no-repeat;
    //   position: relative;
    //   box-sizing: border-box;
    // }
    // .loader::before {
    //   content: '';  
    //   left: 60px;
    //   bottom: 18px;
    //   position: absolute;
    //   width: 36px;
    //   height: 36px;
    //   border-radius: 50%;
    //   background-color: #FF3D00;
    //   background-image: radial-gradient(circle 8px at 18px 18px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 18px 0px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 0px 18px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 36px 18px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 18px 36px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 30px 5px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 30px 30px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 5px 30px, #FFF 100%, transparent 0), radial-gradient(circle 4px at 5px 5px, #FFF 100%, transparent 0);
    //   background-repeat: no-repeat;
    //   box-sizing: border-box;
    //   animation: rotationBack 3s linear infinite;
    // }
    // .loader::after {
    //   content: '';  
    //   left: 94px;
    //   bottom: 15px;
    //   position: absolute;
    //   width: 24px;
    //   height: 24px;
    //   border-radius: 50%;
    //   background-color: #FF3D00;
    //   background-image: radial-gradient(circle 5px at 12px 12px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 12px 0px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 0px 12px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 24px 12px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 12px 24px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 20px 3px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 20px 20px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 3px 20px, #FFF 100%, transparent 0), radial-gradient(circle 2.5px at 3px 3px, #FFF 100%, transparent 0);
    //   background-repeat: no-repeat;
    //   box-sizing: border-box;
    //   animation: rotationBack 4s linear infinite reverse;
    // }
    
    // @keyframes rotationBack {
    //   0% {
    //     transform: rotate(0deg);
    //   }
    //   100% {
    //     transform: rotate(-360deg);
    //   }
    // }
    .loader {
      position: relative;
      display: block;
      width: 130px;
      height: 100px;
      background-repeat: no-repeat;
      background-image: linear-gradient(rgb(189, 2, 15), #bd0202),
      linear-gradient(#f62929, #f74f4f), linear-gradient(#f62929, #f74f4f);
      background-size: 80px 70px, 30px 50px, 30px 30px;
      background-position: 0 0, 80px 20px, 100px 40px;
    }
    .loader:after {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 12px;
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      box-sizing: content-box;
      border: 10px solid #000;
      box-shadow: 78px 0 0 -10px #fff, 78px 0 #000;
      animation: wheelSk 0.75s ease-in infinite alternate;
    }
    
    .loader:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0px;
      height: 70px;
      width: 70px;
      background-image: linear-gradient(#fff 45px, transparent 0),
        linear-gradient(#fff 45px, transparent 0),
        linear-gradient(#fff 45px, transparent 0);
      background-repeat: no-repeat;
      background-size: 30px 4px;
      background-position: 0px 11px, 8px 35px, 0px 60px;
      animation: lineDropping 0.75s linear infinite;
    }
    
    @keyframes wheelSk {
      0%, 50%, 100% { transform: translatey(0) }
      30%, 90% { transform: translatey(-3px) }
    }
    
    @keyframes lineDropping {
      0% {
        background-position: 100px 11px, 115px 35px, 105px 60px;
        opacity: 1;
      }
      50% { background-position: 0px 11px, 20px 35px, 5px 60px }
      60% { background-position: -30px 11px, 0px 35px, -10px 60px }
      75%, 100% {
        background-position: -30px 11px, -30px 35px, -30px 60px;
        opacity: 0;
      }
    }
  }
}

