.grid{
  width: 28rem;
  background-color: #fff;
  margin: 5px;
  height: 38rem;
  position: relative;
  .img{
    padding: 1rem;
    width: 100%;
    max-height: 73%;
    overflow: hidden;
    border-bottom: 2px solid #eee;
    img{
      width: 100%;
      max-width: 100%;
      cursor: pointer;
    }
  }

  .content{
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .details{
      padding: 0 1rem;
      h4{
        font-weight: 400;
        font-size: 1.8rem;
      }

      p{
        font-weight: 600;
        color: red;
      }
    }

    button {
      display: block;
      width: 100%;
      padding: 1rem 0;
      font-size: 2rem;
    }
  }

}

.list{
  width: 100%;
  height: 28rem;
  max-height: 32rem;
  display: flex;
  background-color: #fff;
  margin: 1rem 0;
  .img{
    padding: 1rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-right: 2px solid #eee;
    img{
      width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }

  .content{
    position: relative;
    padding: 1.6rem;
    width: 65%;

    .details{
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      h4{
        font-weight: 400;
      }

      p{
        font-weight: 600;
        color: red;
      }
    }
         
    button{
      position: absolute;
      bottom: 1rem;
      left: 1rem;
    }
  }
}

@media screen and (max-width:520px) {
  .details{
    padding: 0;
    
  }
  .desc{
    font-size: 13px;
    height: 100px;
    overflow: hidden;
    margin-bottom: 2%;
  }
}