.text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3{
    font-weight: 500 !important;
    color: red;
  }
}

.card{
  border: 2px solid red;
  padding: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 10px rgb(186, 186, 186);
}