.product {
  display: flex;
  position: relative;
  margin: 0 auto;
  
  .filter {
    width: 20%;
    // border: 2px solid var(--dark-blue);
    transition: all 0.3s;
  }

  .content {
    width: 80%;
    padding-left: 5px;
    position: relative;
    .icon {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      & > * {
        padding-left: 5px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .filter {
      width: 50%;
      background-color: #fff;
      border: 2px solid var(--dark-blue);
      position: absolute;
      top: 0;
      left: -200%;
      height: 100%;
      padding: 1rem;
      z-index: 99;
    }
    .show {
      left: 0;
    }
    .content {
      width: 100%;
      .icon {
        display: flex;
        border: 1px solid transparent;
        border-radius: 20px;
        background-color: black;
        color: white;
        padding: 0 10px;
        p{
          color: white;
        }
      }
    }
  }
}
