header {
  width: 100%;
  background-color: var(--color-black);
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
  .header {
    width: 100%;
    max-width: 1000px;
    padding: 1rem;
    margin: 0 auto;
    height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .logo h2 {
    color: #fff;
    width: 25%;
    font-size: 2.3rem;
    cursor: pointer;
    span {
      color: red;
      font-size: 3rem;
    }
  }

  nav {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      .logo-mobile {
        display: none;
      }
      li {
        margin: 0 15px;
        position: relative;
        color: #fff;
        a {
          color: #fff;
          // &:hover {
          //   color: #fff;
          // }
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      .cart a{
        display: flex;
        color: #fff;
        position: relative;
        &:hover {
          color: red;
        }
        &:active {
          color: red;
        }
        p {
          color: white;
          font-size: 12px;
          position: absolute;
          top: -1rem;
          right: 4px;
        }
      }
      span {
        // p {
        //   color: #fff;
        // }
      }
      .links {
        a {
          margin: 0 15px;
          position: relative;
          color: #fff;
          // &:hover {
          //   color: red;
          // }
        }

        .user{
          color: red;
          font-weight: 500;
        }
       
        .icon{
          position: relative;
          margin-right: 5px;
          top: 5px;
          font-size: 20px;
          color: red;
        }

        .active{
          color:black;
          font-weight: 500;
        }
      }
    }
  
  }

  .menu-icon {
    cursor: pointer;
    display: none;
  }
  // <-------------------mobile view---------------->
  @media screen and (max-width: 800px) {
    nav {
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100vh;
      background-color: black;
      padding: 1rem;
      z-index: 999;
      transform: translateX(-200%);
      transition: all 0.3s;

      .nav-wrapper {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.3s;
      }

      .show-nav-wrapper {
        transform: translateX(100%);
      }

      ul {
        display: block;
        width: 100%;
        .logo-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > * {
            cursor: pointer;
          }
          & .icon:hover {
            color: red;
            transition: all 0.3s;
          }
        }
        li {
          padding: 5px 0;
          position: relative;
          margin: 0 15px;
          // border-bottom: 1px solid #333;
          a {
            display: block;
            margin: 2px 0px;
          }
        }
      }

      .header-right {
        display: block;
        position: relative;
        width: 100%;
        .cart {
          display: block;
          padding: 15px 0;
          margin: 0px 15px;
          border-bottom: 1px solid #333;
          &:hover{
            color: green;
          }
          a {
            position: relative;
            p {
              position: absolute;
              top: -11px;
              left: 9px;
              font-weight: 400;
            }
            
          }
          
        }

        .links {
          display: block;
          box-sizing: border-box;
          padding-top: 8px ;
          a {
            display: block;
            margin: 0px 15px;
            margin-bottom: 15px;
            // padding: 5px 0;
            // border-bottom: 1px solid #333;
          }
        }
      }
    }

    .cart {
      display: block;
      padding: 5px 0;
      a {
        color: #fff;
        position: relative;
        &:hover {
          color: red;
        }
        p {
          position: absolute;
          top: -1rem;
          right: 4px;
          color: #fff;
        }
      }
    }

    .show-nav {
      transform: translateX(0);
    }

    .hide-nav {
      transform: translateX(-200%);
    }

    .menu-icon {
      display: flex;
      align-items: center;
      > * {
        margin-left: 2rem;
      }
      .cart a {
        display: flex;
        align-items: center;
        p {
          top: -1rem;
          font-size: 12px;
        }
      }
    }
  }

  .active {
    position: relative;
    color: black;
    z-index: 1;
    font-weight: 500;
  }
  
  .active::after {
    content: "";
    height: 100%;
    z-index: -1;
    width: 100%;
    padding: 5px 15px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: -4px;
    border-radius: 20px;
    left: -15px;
    transition: all 0.3s;
    animation: slide-right 0.3s ease;
  }
  
  .admin {
    position: relative;
    color: white;
    background-color: red;
    padding: 8px 10px;
    z-index: 1;
    font-weight: 500;
    border-radius: 3px;
  }
  
  @keyframes slide-right {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
}


