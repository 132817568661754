.footer {
  background-color: black;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  color: #fff;
  // position: absolute;
  // top: 100%;
  // left: 0;
  width: 100%;
}
